import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home mb-auto" }
const _hoisted_2 = { class: "bg-neutral-800" }
const _hoisted_3 = { class: "container mx-auto" }

import Footer from '@/template/Footer.vue';
import NavBar from '@/template/NavBar.vue';
import RocketTicketsNavBar from '@/views/projects/rocket-tickets/RocketTicketsNavBar.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'RocketTicketsPricing',
  setup(__props) {



return (_ctx: any,_cache: any) => {
  const _component_stripe_pricing_table = _resolveComponent("stripe-pricing-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(NavBar)
    ]),
    _createVNode(RocketTicketsNavBar),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_stripe_pricing_table, {
          "pricing-table-id": "prctbl_1QKv5XKBzyZUvQD2TISPEF53",
          "publishable-key": "pk_live_51QFizOKBzyZUvQD2M69gEMRISqKWn9qJVmY1IB5lxitBUj3R49zEWR0Z7Cv3K898jCjKSSTeXZIRVOFyy2yA0yr300abAkWmJA"
        })
      ])
    ]),
    _createVNode(Footer)
  ]))
}
}

})