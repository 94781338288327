import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '@/assets/rocket-tickets/rt-tickets.png'
import _imports_1 from '@/assets/rt2-md.png'


const _hoisted_1 = { class: "mb-auto" }
const _hoisted_2 = { class: "bg-neutral-800" }
const _hoisted_3 = { class: "container mx-auto" }
const _hoisted_4 = { class: "flex flex-col justify-center items-center" }

import NavBar from '@/template/NavBar.vue';
import Footer from '@/template/Footer.vue';
import RocketTicketsWidget from '@/components/home-component/RocketTicketsWidget.vue';
import { onBeforeMount, ref } from 'vue';
import RocketTicketsNavBar from '@/views/projects/rocket-tickets/RocketTicketsNavBar.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'RocketTicketView',
  setup(__props) {

const stores = [
  // {
  //   name: 'Morgan Oasis Nails',
  //   id: 'I3Ina91G01SwYYFDaNiApzxBXkg1'
  // },
  // {
  //   name: 'Perfect Nails Moore',
  //   id: 'G8N9aFUCxfVQNTINxwTppyowkko1'
  // },
  // {
  //   name: 'Dragon Nails Spa',
  //   cute_id: 'dragon-nails-spa'
  // },
  // {
  //   name: 'Nail It',
  //   id: 'FORO9YnEzsgKSeeyzbY7Gzn0oMn2'
  // },
  {
    name: 'Test Salon',
    cute_id: 'test-salon'
  },
];

const atTopOfPage = ref(true);

onBeforeMount(() => {
  window.addEventListener('scroll', handleScroll);
});

function handleScroll() {
  // when the user scrolls, check the pageYOffset
  atTopOfPage.value = window.scrollY <= 0;
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(NavBar)
      ]),
      _createVNode(RocketTicketsNavBar),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "flex flex-col md:flex-row items-center justify-center mt-16" }, [
            _createElementVNode("div", { class: "text-3xl md:text-4xl font-bold text-gray-800" }, "Rocket Tickets")
          ], -1)),
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "mb-8 text-2xl font-bold text-amber-600 px-4 text-center" }, " The only app you need to manage your salon ", -1)),
          _createVNode(_component_router_link, {
            class: "px-6 py-3 h-12 text-amber-900 font-bold bg-amber-500 rounded-full hover:bg-amber-900",
            to: "/rocket-tickets/download"
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode(" Download now ")
            ])),
            _: 1
          }),
          _cache[3] || (_cache[3] = _createStaticVNode("<div class=\"flex flex-row mt-16 mb-8 items-center justify-center px-4\" data-v-647aa450><img alt=\"\" src=\"" + _imports_0 + "\" class=\"md:hidden\" data-v-647aa450><img alt=\"\" src=\"" + _imports_1 + "\" class=\"hidden md:block px-44\" data-v-647aa450></div><div class=\"flex flex-col space-y-4 text-xl md:text-3xl font-bold text-neutral-700\" data-v-647aa450><div data-v-647aa450>Add tickets easily</div><div data-v-647aa450>Manage tickets and bonus tickets</div><div data-v-647aa450>Send report for staffs each day</div><div data-v-647aa450>Calculate payroll</div><div data-v-647aa450>Sync between devices</div><div data-v-647aa450>Schedule appointments</div><div data-v-647aa450>Book appointments online</div></div><div class=\"mt-16 px-6 text-lg\" data-v-647aa450>Online appointment booking example</div>", 3)),
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(stores, (store) => {
            return _createElementVNode("div", {
              key: store.cute_id,
              class: "flex flex-col items-center mb-16"
            }, [
              _createVNode(_component_router_link, {
                class: "text-black bg-amber-500 rounded-xl p-3 m-2",
                to: {name: 'appointment-booking', params: {storeCuteID: store.cute_id }}
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(store.name), 1)
                ]),
                _: 2
              }, 1032, ["to"])
            ])
          }), 64))
        ])
      ])
    ]),
    _createVNode(Footer)
  ], 64))
}
}

})