<!-- eslint-disable max-len -->
<!-- eslint-disable max-len -->
<!-- eslint-disable max-len -->
<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class MyFooter extends Vue {

}
</script>

<template>
  <footer class="bg-neutral-800 auto pt-0 md:pt-8 pb-8 md:pb-8">
    <div class="container mx-auto">
      <div class="flex flex-col-reverse justify-between mx-4 md:mx-16 space-y-8 md:flex-row md:space-y-0">
        <!-- Copyright -->
        <div class="mx-auto pt-8 text-white md:hidden">Copyright &copy; 2022, All Rights Reserved</div>

        <!-- Logo and social links container -->
        <div class="flex flex-row items-center md:justify-start justify-between md:flex-col md:space-y-8 px-2 md:items-start">

          <!-- Logo -->
          <!--        <img src="../assets/logo.png" class="w-16 h-16 md:m-0" alt="">-->
          <div class="text-green-500/85 text-2xl font-jetbrains font-mono font-medium align-left">3AM LAB</div>

          <!--         social links container-->
          <div class="flex justify-center space-x-4">
            <a href="https://www.facebook.com/profile.php?id=61552993627014">
              <img src="../assets/fb.png" class="h-6 mt-1 mr-1 inverted brightness-50" alt="">
            </a>
            <a href="https://x.com/3amlab">
              <img src="../assets/x.png" class="h-6 mt-1 mr-1 inverted brightness-50" alt="">
            </a>
            <a href="https://github.com/txvu100">
              <img src="../assets/github.png" class="h-8 inverted brightness-50" alt="">
            </a>
            <a href="https://www.youtube.com/@trinhvu5731">
              <img src="../assets/youtube.png" class="h-8 inverted brightness-50" alt="">
            </a>
          </div>

          <div class="hidden text-white/85 mt-4 md:block">
            Copyright &copy; 2022, All Rights Reserved
          </div>
        </div>

<!--        Divider-->
        <hr class="md:hidden mx-2 h-[1px] border-t-0 bg-neutral-600" />

        <!-- List container -->
        <div class="flex justify-around text-white/85 md:space-x-32">
          <div class="flex flex-col space-y-3  items-start">
            <a href="" class="hover:text-green-500 font-bold">Projects</a>
            <a href="" class="hover:text-green-500">Rocket Tickets</a>
            <a href="" class="hover:text-green-500">Online Booking</a>
            <a href="" class="hover:text-green-500">Lil' Link</a>
          </div>
          <div class="flex flex-col space-y-3 items-start">
            <a href="" class="hover:text-green-500 font-bold">My Lab</a>
            <a href="" class="hover:text-green-500">About</a>
            <a href="" class="hover:text-green-500">Privacy Policy</a>
          </div>
        </div>


        <!-- Input container -->
        <!--        <flex class="flex flex-col">-->
        <!--          <form action="">-->
        <!--            <div class="flex space-x-3">-->
        <!--              <input type="text" class="flex-1 rounded-full focus:outline-none" placeholder="   Updated in your inbox">-->
        <!--              <button class="px-6 py-2 text-black bg-green-400 rounded-full hover:bg-red-500 focus:outline-none">-->
        <!--                Subscribe-->
        <!--              </button>-->
        <!--            </div>-->

        <!--          </form>-->

        <!--          &lt;!&ndash; Copyright &ndash;&gt;-->
        <!--          <div class="hidden text-white mt-4 md:block">-->
        <!--            Copyright &copy; 2022, All Rights Reserved-->
        <!--          </div>-->
        <!--        </flex>-->

      </div>
    </div>


  </footer>
</template>

<style scoped>
/* Apply the inverted effect using the filter property */
.inverted {
  filter: invert(100%);
}
</style>
