<template>
  <div class="flex flex-col md:w-96 mt-8 items-start bg-neutral-800 text-white/75 p-4 rounded-xl">
    <!--    Divider-->

    <div class="text-green-500/85 text-2xl font-bold">{{ groupName }}</div>

    <div v-for="item in maps" class="flex flex-col mt-8">
      <div class="flex flex-row items-center space-x-2">
      <img :src="item['icon']" class="w-4 h-4 rounded" v-if="item['icon']" alt="">
      <div class="font-bold text-left text-lg">{{ item['institution'] }}</div>
      </div>


      <div class="flex flex-row justify-between w-full">

        <div class="text-green-500/85 text-left">{{ item['title'] }}</div>

        <div class="flex flex-row space-x-1">
          <div>{{ item['start'] }}</div>
          <div v-if="item['start']">-</div>
          <div>{{ item['end'] }}</div>
        </div>
      </div>

      <div class="flex flex-col items-start">
        <div v-for="description in item['descriptions']" class="flex flex-row space-x-2">
          <div>&#x2022;</div>
          <div class="text-left">
            {{ description }}

          </div>
        </div>
      </div>

      <div v-if="item['technologies']" class="flex flex-row justify-start items-start flex-wrap mt-2">
        <div v-for="tech in item['technologies']" class="flex flex-row space-x-4">
          <TechIcon :showText="false" :tech="tech"/>
        </div>
      </div>


      <div class="flex flex-row space-x-0 flex-wrap">
        <div v-for="skill in item['skills']" class="">
          <TechIcon :tech="skill"/>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import TechIcon from '@/components/common/TechIcon.vue';

export default {
  name: 'AboutGroup',
  components: { TechIcon },
  props: {
    groupName: String,
    items: Array,
  },
  data() {
    return {
      maps: this.convertItemsToMaps(),
    };
  },
  methods: {
    convertItemsToMaps() {
      return this.items.map((item) => {
        return item;
      });
    },

  },
};

</script>
