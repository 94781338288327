<template>
  <div class="mb-auto ">
    <div class="bg-neutral-800">
      <NavBar/>
    </div>
    <RocketTicketsNavBar/>

    <div class="container mx-auto">
      <div class="flex flex-col justify-center items-center">

        <div class="flex flex-col md:flex-row items-center justify-center mt-16">
          <!--          <img alt="" src="../../../../src/assets/rocket_tickets.png" class="w-16 h-16 ml-2"/>-->
          <div class="text-3xl md:text-4xl font-bold text-gray-800">Rocket Tickets</div>
        </div>

        <div class="mb-8 text-2xl font-bold text-amber-600 px-4 text-center">
          The only app you need to manage your salon
        </div>

        <router-link
            class="px-6 py-3 h-12 text-amber-900 font-bold bg-amber-500 rounded-full hover:bg-amber-900"
            to="/rocket-tickets/download">
          Download now
        </router-link>

        <div class="flex flex-row mt-16 mb-8 items-center justify-center px-4">
          <img alt="" src="@/assets/rocket-tickets/rt-tickets.png" class="md:hidden"/>
          <img alt="" src="@/assets/rt2-md.png" class="hidden md:block px-44"/>
        </div>

        <!--        <div class="flex flex-col md:flex-row space-y-4 md:space-x-8 py-8 items-start justify-center px-4 bg-amber-500">-->
        <!--          <div class="mb-4 text-3xl font-bold text-neutral-800">Add tickets easily</div>-->
        <!--          <img alt="" src="@/assets/rocket-tickets/rt-add-ticket.png" class="md:hidden rounded-4xl"/>-->
        <!--          <img alt="" src="@/assets/rt2-md.png" class="hidden md:block h-96"/>-->
        <!--        </div>-->

        <div class="flex flex-col space-y-4 text-xl md:text-3xl font-bold text-neutral-700">
          <div>Add tickets easily</div>
          <div>Manage tickets and bonus tickets</div>
          <div>Send report for staffs each day</div>
          <div>Calculate payroll</div>
          <div>Sync between devices</div>
          <div>Schedule appointments</div>
          <div>Book appointments online</div>
        </div>

        <div class="mt-16 px-6 text-lg">Online appointment booking example</div>
        <div v-for="store in stores" :key="store.cute_id" class="flex flex-col items-center mb-16">
          <router-link class="text-black bg-amber-500 rounded-xl p-3 m-2" :to="{name: 'appointment-booking', params: {storeCuteID: store.cute_id }}">{{ store.name }}</router-link>
        </div>


      </div>
    </div>


  </div>


  <!--    Footer place holder    -->
  <Footer/>
  <!--  </div>-->
</template>

<script setup lang="ts">
import NavBar from '@/template/NavBar.vue';
import Footer from '@/template/Footer.vue';
import RocketTicketsWidget from '@/components/home-component/RocketTicketsWidget.vue';
import { onBeforeMount, ref } from 'vue';
import RocketTicketsNavBar from '@/views/projects/rocket-tickets/RocketTicketsNavBar.vue';

const stores = [
  // {
  //   name: 'Morgan Oasis Nails',
  //   id: 'I3Ina91G01SwYYFDaNiApzxBXkg1'
  // },
  // {
  //   name: 'Perfect Nails Moore',
  //   id: 'G8N9aFUCxfVQNTINxwTppyowkko1'
  // },
  // {
  //   name: 'Dragon Nails Spa',
  //   cute_id: 'dragon-nails-spa'
  // },
  // {
  //   name: 'Nail It',
  //   id: 'FORO9YnEzsgKSeeyzbY7Gzn0oMn2'
  // },
  {
    name: 'Test Salon',
    cute_id: 'test-salon'
  },
];

const atTopOfPage = ref(true);

onBeforeMount(() => {
  window.addEventListener('scroll', handleScroll);
});

function handleScroll() {
  // when the user scrolls, check the pageYOffset
  atTopOfPage.value = window.scrollY <= 0;
}
</script>

<style scoped>
.scrolled {
  background-color: #f3f4f6;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
</style>
