import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '../../assets/rocket-ticket-logo.png'
import _imports_1 from '@/assets/rocket-tickets/rt-tickets.png'
import _imports_2 from '@/assets/rt2-md.png'


const _hoisted_1 = { class: "mx-4 md:mx-16 flex flex-col md:flex-row pt-16 pb-8" }
const _hoisted_2 = { class: "flex flex-col md:w-1/2 md:items-start" }
const _hoisted_3 = { class: "ml-24 mt-8 hidden md:block" }
const _hoisted_4 = { class: "my-8 justify-center flex md:hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, { to: "/rocket-tickets" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "flex flex-row items-center" }, [
            _createElementVNode("img", {
              alt: "",
              src: _imports_0,
              class: "w-16 h-16 ml-2 md:ml-0"
            }),
            _createElementVNode("div", { class: "flex flex-col items-start ml-4" }, [
              _createElementVNode("div", { class: "text-3xl md:text-4xl font-bold text-amber-900" }, "Rocket Tickets"),
              _createElementVNode("div", { class: "text-amber-800" }, "#mobile-app")
            ])
          ], -1)),
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "mt-6 text-lg md:text-2xl md:text-left text-center font-bold text-amber-900 md:ml-20" }, [
            _createTextVNode(" An app to manage your salon"),
            _createElementVNode("br"),
            _createTextVNode("tickets, appointments, and payrolls. ")
          ], -1)),
          _cache[3] || (_cache[3] = _createElementVNode("p", { class: "hidden md:block text-start md:ml-20" }, [
            _createElementVNode("br"),
            _createTextVNode("• Sync your data across devices in real-time "),
            _createElementVNode("br"),
            _createTextVNode("• Send daily report to staffs and managers via email/SMS "),
            _createElementVNode("br"),
            _createTextVNode("• Calculate payroll "),
            _createElementVNode("br"),
            _createTextVNode("• Easy to enter appointments/tickets "),
            _createElementVNode("br"),
            _createTextVNode("• Send SMS reminder to customers "),
            _createElementVNode("br"),
            _createTextVNode("• Prevent edit tickets without permission "),
            _createElementVNode("br"),
            _createTextVNode("• Easy to work even with no receptionist ")
          ], -1)),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_router_link, {
              class: "px-4 py-2 text-amber-500 justify-end bg-amber-900 rounded-full hover:bg-amber-900 baseline",
              to: "/rocket-tickets"
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode("Learn more "),
                _createElementVNode("i", { class: "fa-solid fa-angle-right" }, null, -1)
              ])),
              _: 1
            })
          ])
        ]),
        _cache[5] || (_cache[5] = _createElementVNode("div", { class: "my-10 md:mt-0 md:w-1/2 justify-center" }, [
          _createElementVNode("img", {
            alt: "",
            src: _imports_1,
            class: "md:hidden"
          }),
          _createElementVNode("img", {
            alt: "",
            src: _imports_2,
            class: "hidden md:block"
          })
        ], -1)),
        _cache[6] || (_cache[6] = _createElementVNode("p", { class: "md:hidden text-amber-900" }, [
          _createElementVNode("br"),
          _createTextVNode("• Sync your data across devices in real-time "),
          _createElementVNode("br"),
          _createTextVNode("• Send daily report to staffs and managers via email/SMS "),
          _createElementVNode("br"),
          _createTextVNode("• Calculate payroll "),
          _createElementVNode("br"),
          _createTextVNode("• Easy to enter appointments/tickets "),
          _createElementVNode("br"),
          _createTextVNode("• Send SMS reminder to customers "),
          _createElementVNode("br"),
          _createTextVNode("• Prevent edit tickets without permission "),
          _createElementVNode("br"),
          _createTextVNode("• Easy to work even with no receptionist ")
        ], -1)),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_router_link, {
            class: "px-4 py-2 text-amber-500 justify-end bg-amber-900 rounded-full hover:bg-amber-900 baseline",
            to: "/rocket-tickets"
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode("Learn more "),
              _createElementVNode("i", { class: "fa-solid fa-angle-right" }, null, -1)
            ])),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }))
}