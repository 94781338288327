<template>
  <div class="sticky bg-neutral-800/50 isolate text-white/85 backdrop-blur ring-[0.5px] ring-neutral-500/50 top-0 h-16">
    <div class="container flex flex-col mx-auto">
      <div class="flex items-center justify-between mx-4 md:mx-16">
        <!-- Logo -->
        <div class="">
          <router-link to="/">
            <img src="../assets/logo.png" alt="" class="w-16 h-16"/>
          </router-link>
        </div>
        <!-- Menu -->
        <div class="flex space-x-4 md:space-x-8 items-center text-white/85 font-medium">
          <router-link v-if="getSelectedTab() === 'home'" class=" font-bold border-b-2 border-green-500 text-green-500" to="/">Home</router-link>
          <router-link v-else class="hover:text-green-500 font-medium" to="/">Home</router-link>

          <button v-if="getSelectedTab() === 'projects'" class=" md:block font-bold border-b-2 border-green-500 text-green-500" @click="handleShowProjects">Projects</button>
          <button v-else class="hover:text-green-500 font-medium md:block" @click="handleShowProjects">Projects</button>

          <router-link v-if="getSelectedTab() === 'blogs'" class=" font-bold border-b-2 border-green-500 text-green-500" to="/blogs">Blogs</router-link>
          <router-link v-else class="hover:text-green-500 font-medium" to="/blogs">Blogs</router-link>

          <router-link v-if="getSelectedTab() === 'about'" class=" font-bold border-b-2 border-green-500 text-green-500" to="/about">About</router-link>
          <router-link v-else class="hover:text-green-500 font-medium" to="/about">About</router-link>
        </div>
      </div>
    </div>

    <Transition name="modal" @click="">
      <div v-if="showProjects" class="modal-mask" @click="handleShowProjects">
        <div class="absolute left-0 right-0 container mx-auto">

          <div class="flex md:flex-row flex-col md:items-start justify-start space-y-4 md:space-y-0  py-4 px-4 mx-4 md:mx-16 bg-neutral-200 text-black rounded-b-lg">

            <router-link class="flex flex-row flex-1 items-center space-x-2 h-10" to="/rocket-tickets">
              <img src="../assets/rocket-ticket-logo.png" alt="" class="w-9 rounded-lg"/>
              <div class="flex flex-col items-start">
                <div class="">Rocket Tickets</div>
                <div class="text-sm text-gray-500">Salon management app</div>
              </div>
            </router-link>

            <router-link class="flex flex-row flex-1 items-center space-x-2 h-10" to="/rocket-tickets">
              <div class="w-9 h-9 rounded-lg bg-indigo-500">.</div>
              <div class="flex flex-col items-start">
                <div class="">Appointment Booking</div>
                <div class="text-sm text-gray-500">Online appointment booking service</div>
              </div>
            </router-link>

            <a class="flex flex-row flex-1 items-center space-x-2 h-10" target="_blank" rel="noopener noreferrer" href="https://lillink.co">
              <div class="w-9 h-9 rounded-lg bg-blue-600">.</div>
              <div class="flex flex-col items-start">
                <div class="">Lil' Link</div>
                <div class="text-sm text-gray-500">URL shortener/QR code</div>
              </div>
            </a>

          </div>
        </div>
      </div>
    </Transition>

    <!--      Divider-->
<!--    <hr class="h-[1px] border-t-0 bg-neutral-600"/>-->

  </div>


</template>

<script setup lang="ts">

import { ref } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();

const showProjects = ref(false);

function handleShowProjects() {
  showProjects.value = !showProjects.value;
}

function getSelectedTab() {
  console.log(route.path);
  if (route.path === '/' || route.path === '/home') {
    return 'home';
  }
  else {
    const pathArray = route.path.split('/');
    if (pathArray[1].toLowerCase() == 'rocket-tickets') {
      return 'projects';
    } else {
      return pathArray[1].toLowerCase();
    }
  }
}
</script>

<style lang="css">

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 16;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
