import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Transition as _Transition } from "vue"
import _imports_0 from '../assets/logo.png'
import _imports_1 from '../assets/rocket-ticket-logo.png'


const _hoisted_1 = { class: "sticky bg-neutral-800/50 isolate text-white/85 backdrop-blur ring-[0.5px] ring-neutral-500/50 top-0 h-16" }
const _hoisted_2 = { class: "container flex flex-col mx-auto" }
const _hoisted_3 = { class: "flex items-center justify-between mx-4 md:mx-16" }
const _hoisted_4 = { class: "" }
const _hoisted_5 = { class: "flex space-x-4 md:space-x-8 items-center text-white/85 font-medium" }
const _hoisted_6 = { class: "absolute left-0 right-0 container mx-auto" }
const _hoisted_7 = { class: "flex md:flex-row flex-col md:items-start justify-start space-y-4 md:space-y-0 py-4 px-4 mx-4 md:mx-16 bg-neutral-200 text-black rounded-b-lg" }

import { ref } from 'vue';
import { useRoute } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'NavBar',
  setup(__props) {


const route = useRoute();

const showProjects = ref(false);

function handleShowProjects() {
  showProjects.value = !showProjects.value;
}

function getSelectedTab() {
  console.log(route.path);
  if (route.path === '/' || route.path === '/home') {
    return 'home';
  }
  else {
    const pathArray = route.path.split('/');
    if (pathArray[1].toLowerCase() == 'rocket-tickets') {
      return 'projects';
    } else {
      return pathArray[1].toLowerCase();
    }
  }
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_router_link, { to: "/" }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createElementVNode("img", {
                src: _imports_0,
                alt: "",
                class: "w-16 h-16"
              }, null, -1)
            ])),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_5, [
          (getSelectedTab() === 'home')
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                class: "font-bold border-b-2 border-green-500 text-green-500",
                to: "/"
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode("Home")
                ])),
                _: 1
              }))
            : (_openBlock(), _createBlock(_component_router_link, {
                key: 1,
                class: "hover:text-green-500 font-medium",
                to: "/"
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode("Home")
                ])),
                _: 1
              })),
          (getSelectedTab() === 'projects')
            ? (_openBlock(), _createElementBlock("button", {
                key: 2,
                class: "md:block font-bold border-b-2 border-green-500 text-green-500",
                onClick: handleShowProjects
              }, "Projects"))
            : (_openBlock(), _createElementBlock("button", {
                key: 3,
                class: "hover:text-green-500 font-medium md:block",
                onClick: handleShowProjects
              }, "Projects")),
          (getSelectedTab() === 'blogs')
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 4,
                class: "font-bold border-b-2 border-green-500 text-green-500",
                to: "/blogs"
              }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createTextVNode("Blogs")
                ])),
                _: 1
              }))
            : (_openBlock(), _createBlock(_component_router_link, {
                key: 5,
                class: "hover:text-green-500 font-medium",
                to: "/blogs"
              }, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createTextVNode("Blogs")
                ])),
                _: 1
              })),
          (getSelectedTab() === 'about')
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 6,
                class: "font-bold border-b-2 border-green-500 text-green-500",
                to: "/about"
              }, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode("About")
                ])),
                _: 1
              }))
            : (_openBlock(), _createBlock(_component_router_link, {
                key: 7,
                class: "hover:text-green-500 font-medium",
                to: "/about"
              }, {
                default: _withCtx(() => _cache[7] || (_cache[7] = [
                  _createTextVNode("About")
                ])),
                _: 1
              }))
        ])
      ])
    ]),
    _createVNode(_Transition, {
      name: "modal",
      onClick: _cache[0] || (_cache[0] = () => {})
    }, {
      default: _withCtx(() => [
        (showProjects.value)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "modal-mask",
              onClick: handleShowProjects
            }, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_router_link, {
                    class: "flex flex-row flex-1 items-center space-x-2 h-10",
                    to: "/rocket-tickets"
                  }, {
                    default: _withCtx(() => _cache[8] || (_cache[8] = [
                      _createElementVNode("img", {
                        src: _imports_1,
                        alt: "",
                        class: "w-9 rounded-lg"
                      }, null, -1),
                      _createElementVNode("div", { class: "flex flex-col items-start" }, [
                        _createElementVNode("div", { class: "" }, "Rocket Tickets"),
                        _createElementVNode("div", { class: "text-sm text-gray-500" }, "Salon management app")
                      ], -1)
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_router_link, {
                    class: "flex flex-row flex-1 items-center space-x-2 h-10",
                    to: "/rocket-tickets"
                  }, {
                    default: _withCtx(() => _cache[9] || (_cache[9] = [
                      _createElementVNode("div", { class: "w-9 h-9 rounded-lg bg-indigo-500" }, ".", -1),
                      _createElementVNode("div", { class: "flex flex-col items-start" }, [
                        _createElementVNode("div", { class: "" }, "Appointment Booking"),
                        _createElementVNode("div", { class: "text-sm text-gray-500" }, "Online appointment booking service")
                      ], -1)
                    ])),
                    _: 1
                  }),
                  _cache[10] || (_cache[10] = _createElementVNode("a", {
                    class: "flex flex-row flex-1 items-center space-x-2 h-10",
                    target: "_blank",
                    rel: "noopener noreferrer",
                    href: "https://lillink.co"
                  }, [
                    _createElementVNode("div", { class: "w-9 h-9 rounded-lg bg-blue-600" }, "."),
                    _createElementVNode("div", { class: "flex flex-col items-start" }, [
                      _createElementVNode("div", { class: "" }, "Lil' Link"),
                      _createElementVNode("div", { class: "text-sm text-gray-500" }, "URL shortener/QR code")
                    ])
                  ], -1))
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}
}

})