<script lang="ts">
import Vue from 'vue';
import Options from 'vue-class-component';

// @Options({})
// export default RocketTicketsWidget

export default {
  name: 'RocketTicketsWidget',
};
</script>

<template>
  <router-link to="/rocket-tickets">
    <div class="mx-4 md:mx-16 flex flex-col md:flex-row pt-16 pb-8">
      <div class="flex flex-col md:w-1/2 md:items-start">
        <div class="flex flex-row items-center">
          <img alt="" src="../../assets/rocket-ticket-logo.png" class="w-16 h-16 ml-2 md:ml-0" />
          <div class="flex flex-col items-start ml-4">
            <div class="text-3xl md:text-4xl font-bold text-amber-900">Rocket Tickets</div>
            <div class="text-amber-800">#mobile-app</div>
          </div>
        </div>

        <div class="mt-6 text-lg md:text-2xl md:text-left text-center font-bold text-amber-900 md:ml-20">
          An app to manage your salon<br/>tickets, appointments, and payrolls.
        </div>

<!--        <div class="flex flex-col md:flex-row items-center md:items-baseline md:ml-14">-->
<!--          <div class="my-6 mx-4">-->
<!--            <router-link-->
<!--              class="px-6 py-3 h-12 w-40 text-amber-500 justify-end bg-amber-900 rounded-full hover:bg-amber-900 baseline"-->
<!--              to="/rocket-tickets">-->
<!--              Download now-->
<!--            </router-link>-->
<!--          </div>-->
<!--        </div>-->

        <p class="hidden md:block text-start md:ml-20">
          <br />&#x2022; Sync your data across devices in real-time
          <br />&#x2022; Send daily report to staffs and managers via email/SMS
          <br />&#x2022; Calculate payroll
          <br />&#x2022; Easy to enter appointments/tickets
          <br />&#x2022; Send SMS reminder to customers
          <br />&#x2022; Prevent edit tickets without permission
          <br />&#x2022; Easy to work even with no receptionist
        </p>

<!--        <div class="ml-24 mt-8 hidden md:block">-->
<!--          <router-link-->
<!--              class="text-amber-900 font-bold underline"-->
<!--              to="/rocket-tickets"-->
<!--          >Learn more >>></router-link-->
<!--          >-->
<!--        </div>-->
        <div class="ml-24 mt-8 hidden md:block">
          <router-link
              class="px-4 py-2 text-amber-500 justify-end bg-amber-900 rounded-full hover:bg-amber-900 baseline"
              to="/rocket-tickets"
          >Learn more <i class="fa-solid fa-angle-right"></i></router-link
          >
        </div>
      </div>

      <div class="my-10 md:mt-0 md:w-1/2 justify-center">
<!--        <img alt="" src="../../assets/rt-2.png" class="md:hidden" />-->
<!--        <img alt="" src="../../assets/rt2-md.png" class="hidden md:block" />-->
        <img alt="" src="@/assets/rocket-tickets/rt-tickets.png" class="md:hidden"/>
        <img alt="" src="@/assets/rt2-md.png" class="hidden md:block"/>
      </div>

      <p class="md:hidden text-amber-900">
        <br />&#x2022; Sync your data across devices in real-time
        <br />&#x2022; Send daily report to staffs and managers via email/SMS
        <br />&#x2022; Calculate payroll
        <br />&#x2022; Easy to enter appointments/tickets
        <br />&#x2022; Send SMS reminder to customers
        <br />&#x2022; Prevent edit tickets without permission
        <br />&#x2022; Easy to work even with no receptionist
      </p>

      <div class="my-8 justify-center flex md:hidden">
        <router-link
          class="px-4 py-2 text-amber-500 justify-end bg-amber-900 rounded-full hover:bg-amber-900 baseline"
          to="/rocket-tickets"
          >Learn more <i class="fa-solid fa-angle-right"></i></router-link
        >
      </div>

<!--      <div class="mt-10 md:hidden">-->
<!--        <router-link-->
<!--            class="text-amber-900 font-bold underline"-->
<!--            to="/rocket-tickets"-->
<!--        >Learn more >>></router-link-->
<!--        >-->
<!--      </div>-->

      <!--              <div class="mt-2">-->
      <!--                <strong class="text-amber-500">Learn more</strong>-->
      <!--              </div>-->
      <!--      <div>-->
      <!--        lalal-->
      <!--      </div>-->
    </div>
  </router-link>
</template>

<style scoped></style>
