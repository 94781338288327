import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home mb-auto" }
const _hoisted_2 = { class: "bg-neutral-800" }

import Footer from '@/template/Footer.vue';
import NavBar from '@/template/NavBar.vue';
import RocketTicketsNavBar from '@/views/projects/rocket-tickets/RocketTicketsNavBar.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'RocketTicketsDownload',
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(NavBar)
    ]),
    _createVNode(RocketTicketsNavBar),
    _cache[0] || (_cache[0] = _createStaticVNode("<div><div class=\"container mx-auto\"><div class=\"my-8 ml-4 text-2xl pr-8 text-left text-gray-500 mx-4 md:mx-16\"><strong>Download at</strong></div><div class=\"flex flex-row text-gray-600 w-screen mx-4 md:mx-16\"><div class=\"grow\"></div><div class=\"pl-2.5 pt-2.5 pr-2.5 pb-2.5 w-36\"><a href=\"https://apps.apple.com/us/app/rocket-tickets/id6445938738\" title=\"Apple App Store\" target=\"_blank\"><img src=\"https://upload.wikimedia.org/wikipedia/commons/3/3c/Download_on_the_App_Store_Badge.svg\" alt=\"Apple App Store\" title=\"Apple App Store\"></a></div><div class=\"grow\"></div><div class=\"w-36\"><a href=\"https://play.google.com/store/apps/details?id=dev.u3amlab.no_receptionist&amp;pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1\"><img alt=\"Get it on Google Play\" src=\"https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png\"></a></div><div class=\"grow\"></div></div><div class=\"flex flex-row text-gray-600 w-screen mx-4 md:mx-16\"><div class=\"grow\"></div><div class=\"pl-2.5 pt-2.5 pr-2.5 pb-2.5 w-36\"><a href=\"https://www.amazon.com/gp/product/B0BX34QYHC\" title=\"Amazon AppStore\" target=\"_blank\"><img src=\"https://images-na.ssl-images-amazon.com/images/G/01/mobile-apps/devportal2/res/images/amazon-appstore-badge-english-black.png\" alt=\"Amazon AppStore\" title=\"Amazon AppStore\"></a></div><div class=\"grow\"></div><div class=\"pl-2.5 pt-2.5 pr-2.5 pb-2.5 w-36\"></div><div class=\"grow\"></div></div><div class=\"my-16 ml-4 w-screen pr-8 text-left text-gray-500 mx-4 md:mx-16\"> If you intended to use Kindle Fire tablet for this app. You&#39;d want to create an account with an email instead of Google or Apple. </div></div></div>", 1)),
    _createVNode(Footer)
  ]))
}
}

})