<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import RocketTicketsTabButton from '@/views/projects/rocket-tickets/RocketTicketsTabButton.vue';
import { useCurrentUser } from 'vuefire'
import { getAuth, signOut } from "firebase/auth";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import router from '@/router';


const user = useCurrentUser()
const auth = getAuth();

function getShortName() {
  return user.value.displayName.split(' ')[0];
}

function signOutUser() {
  signOut(auth).then(() => {
    router.push('/rocket-tickets/auth');
  }).catch((error) => {
    console.log('Sign out error');
  });
}

</script>

<template>
  <div class="w-full bg-white sticky top-0">
    <div class="container mx-auto flex flex-col h-16">
      <div class="flex flex-row justify-between items-center mx-4 md:mx-16" >
        <router-link class="flex flex-row items-center" to="/rocket-tickets">
          <img alt="" src="../../../../src/assets/rocket_tickets.png" class="w-16 h-16 ml-0 md:ml-0"/>
          <div class="hidden md:block text-xl md:text-xl font-bold text-gray-800">Rocket Tickets</div>
        </router-link>
        <div class="flex flex-row text-sm font-medium space-x-4 items-baseline">
          <RocketTicketsTabButton tabName="Overview" pathName="" class="hidden md:block"/>
          <RocketTicketsTabButton tabName="Download" pathName="download"/>
          <RocketTicketsTabButton tabName="Pricing" pathName="pricing"/>
          <RocketTicketsTabButton tabName="Guide" pathName="guide"/>
          <RocketTicketsTabButton v-if="!user" tabName="Sign in" pathName="auth"/>

<!--          <RocketTicketsTabButton v-else :tabName="getShortName()" pathName="account"/>-->
          <DropdownMenu v-else>
            <DropdownMenuTrigger as-child>
              <Button class=" bg-amber-500  text-amber-800 rounded-full px-2">
                {{ getShortName() }}
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent class="w-56">
              <DropdownMenuLabel>My Account</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuGroup>
                <DropdownMenuItem>
                  <User class="mr-2 h-4 w-4" />
                  <router-link to="/rocket-tickets/store">Profile</router-link>
                </DropdownMenuItem>
                <DropdownMenuItem>
                  <CreditCard class="mr-2 h-4 w-4" />
                  <router-link to="/rocket-tickets/store/billing">Billing</router-link>
                </DropdownMenuItem>
<!--                <DropdownMenuItem>-->
<!--                  <Settings class="mr-2 h-4 w-4" />-->
<!--                  <span>Settings</span>-->
<!--                </DropdownMenuItem>-->
              </DropdownMenuGroup>
              <DropdownMenuSeparator />
              <DropdownMenuItem>
                <LogOut class="mr-2 h-4 w-4" />
                <button @click="signOutUser">Sign out</button>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>

        </div>
      </div>
    </div>
  </div>

</template>
