<!-- eslint-disable max-len -->
<template>
  <div class="home bg-neutral-900">
    <NavBar />

<!--     Hero Section-->
    <section id="hero" class="bg-neutral-900">
      <div class="container flex flex-col mx-auto space-y-0 md:space-y-0 justify-evenly ">
        <img src="../assets/banner.png" alt="" class="md:hidden">
        <img src="../assets/banner2.jpg" alt="" class="hidden md:block rounded-b-xl md:mx-16">

      </div>
    </section>

    <div class="bg-neutral-900 text-white/85">
      <div class="container mx-auto">
        <div class="flex flex-col md:flex-row justify-center items-center mx-4 md:mx-16 py-16 md:py-28">
          <div class="text-4xl md:w-1/2 font-bold font-jetbrains font-mono md:text-6xl text-center md:text-left">
            Software Lab
            <div class="text-lg text-green-500/85 ml-2 mt-2">Fueled by iced coffee</div>
          </div>
          <div class="flex flex-col md:w-1/2 mt-16 md:mt-2 justify-center">
            <div class="text-center justify-center md:text-left">
              Howdy! I'm Isaac, and welcome to my world of side projects – <span class="text-green-500/85">3AM Lab</span>.
              Born during my college "vampire mode" nights, these creations are fueled by late-night inspiration.
              Although my day job is now a thing, the 3AM name lives on because that's when software gets made.
              <br/>Happy coding and stay caffeinated 🔥
            </div>
            <div class="flex justify-center md:justify-start mt-4 md:mt-4">
              <router-link class="px-4 py-2 h-10 text-black bg-green-500/75 rounded-full hover:bg-green-500 baseline" to="/about">Contact me</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class=" container mx-auto md:mb-4">
        <div class="bg-neutral-800 md:mx-16 md:rounded-xl flex flex-col md:flex-row py-8 md:space-x-8 md:items-start md:justify-items-start items-center justify-center text-sm md:text-md font-jetbrains font-mono">
          <div class="text-gray-400"># What I'm working on</div>
          <div class="text-white/85"><span class="text-green-500/85">trinhvu@3amlab</span>:<span class="text-blue-500/85">~/projects</span>$ ls -l</div>
        </div>
    </div>

    <div class="bg-amber-500">
      <div class="container mx-auto">
        <RocketTicketsWidget />
      </div>
    </div>

    <Footer />

  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import HomeBanner from '@/components/home-component/HomeBanner.vue';
import RocketTicketsWidget from '@/components/home-component/RocketTicketsWidget.vue';
import NavBar from '@/template/NavBar.vue';
import Footer from '@/template/Footer.vue';

@Options({
  components: {
    NavBar,
    Footer,
    HomeBanner,
    RocketTicketsWidget,
  },
})
export default class HomeView extends Vue { }
</script>
